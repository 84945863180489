/**
 * removes the storage indicated by the passed key from the session storage
 * @param key denotes storage key 
 * 
 */ export var removeToken = function(key) {
    if ("object" !== "undefined") {
        return window.sessionStorage.removeItem(key);
    }
    return false;
};
