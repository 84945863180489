// @ts-nocheck
import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _class_call_check from "@swc/helpers/src/_class_call_check.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-business-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import ky from "ky";
import { API } from "@vfit/shared/data-access";
import { options as defaultOptions } from "./const";
import { LoggerInstance } from "../logger";
export var HttpClient = function HttpClient(clientType, customOptions) {
    "use strict";
    _class_call_check(this, HttpClient);
    var _this = this;
    this.get = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, options) {
            var notJsonParsing, customHooks, response;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        notJsonParsing = (options || {
                            notJsonParsing: false
                        }).notJsonParsing;
                        customHooks = (options === null || options === void 0 ? void 0 : options.hooks) || defaultOptions[_this.clientType].hooks;
                        _ctx.prev = 2;
                        LoggerInstance.debug("API chiamata ".concat(endpoint));
                        _ctx.next = 6;
                        return _this.webConsumerClient.get(API[endpoint] || endpoint, _object_spread({}, options, customHooks && {
                            hooks: customHooks
                        }));
                    case 6:
                        response = _ctx.sent;
                        if (!notJsonParsing) {
                            _ctx.next = 9;
                            break;
                        }
                        return _ctx.abrupt("return", response);
                    case 9:
                        _ctx.next = 11;
                        return response.json();
                    case 11:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 14:
                        _ctx.prev = 14;
                        _ctx.t0 = _ctx["catch"](2);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 18:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    2,
                    14
                ]
            ]);
        }));
        return function(endpoint, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this1 = this;
    this.post = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload, options) {
            var notJsonParsing, customHooks, response;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        notJsonParsing = (options || {
                            notJsonParsing: false
                        }).notJsonParsing;
                        customHooks = (options === null || options === void 0 ? void 0 : options.hooks) || defaultOptions[_this1.clientType].hooks;
                        _ctx.prev = 2;
                        _ctx.next = 5;
                        return _this1.webConsumerClient.post(API[endpoint] || endpoint, _object_spread({}, options, payload && {
                            json: payload
                        }, customHooks && {
                            hooks: customHooks
                        }));
                    case 5:
                        response = _ctx.sent;
                        if (!notJsonParsing) {
                            _ctx.next = 8;
                            break;
                        }
                        return _ctx.abrupt("return", response);
                    case 8:
                        _ctx.next = 10;
                        return response.json();
                    case 10:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 13:
                        _ctx.prev = 13;
                        _ctx.t0 = _ctx["catch"](2);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 17:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    2,
                    13
                ]
            ]);
        }));
        return function(endpoint, payload, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this2 = this;
    this.put = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload, options) {
            var notJsonParsing, response;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        notJsonParsing = (options || {
                            notJsonParsing: false
                        }).notJsonParsing;
                        _ctx.prev = 1;
                        _ctx.next = 4;
                        return _this2.webConsumerClient.put(API[endpoint] || endpoint, _object_spread({}, options, payload && {
                            json: payload
                        }));
                    case 4:
                        response = _ctx.sent;
                        if (!notJsonParsing) {
                            _ctx.next = 7;
                            break;
                        }
                        return _ctx.abrupt("return", response);
                    case 7:
                        _ctx.next = 9;
                        return response.json();
                    case 9:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 12:
                        _ctx.prev = 12;
                        _ctx.t0 = _ctx["catch"](1);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 16:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    1,
                    12
                ]
            ]);
        }));
        return function(endpoint, payload, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this3 = this;
    this.delete = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload, options) {
            var notJsonParsing, response;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        notJsonParsing = (options || {
                            notJsonParsing: false
                        }).notJsonParsing;
                        _ctx.prev = 1;
                        _ctx.next = 4;
                        return _this3.webConsumerClient.delete(API[endpoint] || endpoint, _object_spread({}, options, payload && {
                            json: payload
                        }));
                    case 4:
                        response = _ctx.sent;
                        if (!notJsonParsing) {
                            _ctx.next = 7;
                            break;
                        }
                        return _ctx.abrupt("return", response);
                    case 7:
                        _ctx.next = 9;
                        return response.json();
                    case 9:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 12:
                        _ctx.prev = 12;
                        _ctx.t0 = _ctx["catch"](1);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 16:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    1,
                    12
                ]
            ]);
        }));
        return function(endpoint, payload, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this4 = this;
    this.head = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, options) {
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.prev = 0;
                        _ctx.next = 3;
                        return _this4.webConsumerClient.head(API[endpoint] || endpoint, _object_spread({}, options)).json();
                    case 3:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 6:
                        _ctx.prev = 6;
                        _ctx.t0 = _ctx["catch"](0);
                        throw new Error("error, /todoImplement error manager: ".concat(_ctx.t0));
                    case 9:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    0,
                    6
                ]
            ]);
        }));
        return function(endpoint, options) {
            return _ref.apply(this, arguments);
        };
    }();
    var _this5 = this;
    this.patch = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload, options) {
            var notJsonParsing, response;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        notJsonParsing = (options || {
                            notJsonParsing: false
                        }).notJsonParsing;
                        _ctx.prev = 1;
                        _ctx.next = 4;
                        return _this5.webConsumerClient.patch(API[endpoint] || endpoint, _object_spread({}, options, payload && {
                            json: payload
                        }));
                    case 4:
                        response = _ctx.sent;
                        if (!notJsonParsing) {
                            _ctx.next = 7;
                            break;
                        }
                        return _ctx.abrupt("return", response);
                    case 7:
                        _ctx.next = 9;
                        return response.json();
                    case 9:
                        return _ctx.abrupt("return", _ctx.sent);
                    case 12:
                        _ctx.prev = 12;
                        _ctx.t0 = _ctx["catch"](1);
                        LoggerInstance.error("error", _ctx.t0);
                        throw _ctx.t0;
                    case 16:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    1,
                    12
                ]
            ]);
        }));
        return function(endpoint, payload, options) {
            return _ref.apply(this, arguments);
        };
    }();
    this.clientType = clientType;
    this.baseURL = defaultOptions[clientType].baseUrl;
    this.baseHeaders = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) || defaultOptions[clientType].headers;
    this.prefixUrl = defaultOptions[clientType].prefixUrl;
    this.hooks = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.hooks) || defaultOptions[clientType].hooks;
    this.mode = defaultOptions[clientType].mode;
    this.credentials = defaultOptions[clientType].credentials;
    this.timeout = defaultOptions[clientType].timeout || 10000;
    this.searchParams = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.searchParams) || defaultOptions[clientType].searchParams;
    this.retry = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.retry) || defaultOptions[clientType].retry;
    this.webConsumerClient = ky.create(_object_spread({
        retry: this.retry
    }, this.prefixUrl && {
        prefixUrl: this.prefixUrl
    }, this.baseHeaders && {
        headers: this.baseHeaders
    }, this.hooks && {
        hooks: this.hooks
    }, this.searchParams && {
        searchParams: this.searchParams
    }, this.mode && {
        mode: this.mode
    }, this.credentials && {
        credentials: this.credentials
    }, this.timeout && {
        timeout: this.timeout
    }));
};
export var nextClient = new HttpClient("NEXT");
export var cmsClient = new HttpClient("CMS");
export var serverlessClient = new HttpClient("WEBAPI_SERVERLESS");
